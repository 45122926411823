<template>
  <v-container class="contact-form">
    <h5 class="text-uppercase flex-grow-1">{{ $t("contactForm.title") }}</h5>
    <v-form
      class="d-flex flex-wrap"
      ref="form"
      @submit.prevent="sendMesssage"
      :key="key"
    >
      <v-row>
        <v-col cols="12" md="6">
          <label>{{ $t("contactForm.form.name") }}</label>
          <v-text-field
            :placeholder="`${$t('contactForm.form.name')}`"
            class="mt-1"
            outlined
            rounded
            dense
            type="text"
            hide-details="auto"
            height="50"
            v-model="data.name"
            :rules="[requiredRules()]"
          />
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("contactForm.form.email") }}</label>
          <v-text-field
            :placeholder="`${$t('contactForm.form.email')}`"
            class="mt-1"
            outlined
            rounded
            dense
            type="text"
            hide-details="auto"
            height="50"
            v-model="data.email"
            :rules="[requiredRules()]"
          />
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("contactForm.form.fidelityCard") }}</label>
          <v-text-field
            :placeholder="`${$t('contactForm.form.fidelityCard')}`"
            class="mt-1"
            outlined
            rounded
            dense
            type="text"
            hide-details="auto"
            height="50"
            v-model="data.card"
          />
        </v-col>
        <v-col cols="12">
          <label>{{ $t("contactForm.form.message") }}</label>
          <v-textarea
            :placeholder="`${$t('contactForm.form.messagePlaceholder')}`"
            class="mt-1"
            outlined
            rounded
            dense
            type="text"
            hide-details="auto"
            no-resize
            auto-grow
            v-model="data.message"
            :rules="[requiredRules()]"
          />
        </v-col>
        <v-col cols="12">
          <i18n
            path="contactForm.privacyInfo"
            tag="div"
            class="pre-wrap mt-2 text-body-2"
          >
            <template v-slot:link>
              <span
                class="text-decoration-underline"
                @click.prevent.stop="
                  handleRedirect(
                    'https://www.galasupermercati.it/privacy-parla-con-noi'
                  )
                "
                >{{ $t("contactForm.privacyLink") }}</span
              >
            </template>
          </i18n>
        </v-col>
        <v-col cols="0" md="6"></v-col>
        <v-col cols="12" md="6">
          <ResponseMessage class="mt-3" :response="responseMessage" />
          <v-btn
            color="primary"
            type="submit"
            x-large
            depressed
            rounded
            block
            class="text-body-0"
            :loading="loading"
            >{{ $t("contactForm.form.send") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<style lang="scss">
.contact-form {
  label {
    font-weight: 600;
  }
  .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
    textarea {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
</style>
<script>
import CustomService from "@/service/customService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import { mapGetters } from "vuex";
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "ContactForm",
  components: { ResponseMessage },
  data() {
    return {
      data: {},
      key: 1,
      requiredRules: requiredValue,
      loading: false,
      responseMessage: {}
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    sendMesssage() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.responseMessage = {};
        CustomService.sentEmail(this.data)
          .then(res => {
            if (res.response.status === 0) {
              this.data.message = "";
              this.$refs.form.resetValidation();
            }
            this.responseMessage = res.response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleRedirect(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.data.name = this.user.firstName + " " + this.user.lastName;
      this.data.email = this.user.email;
      this.data.card = this.user.fidelityCard;
      this.key++;
    }
  }
};
</script>
